import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import VisibilitySensor from "react-visibility-sensor";
import { globalHistory } from "@reach/router";

import { ScreenWidthContext, FontLoadedContext } from "../../layouts";
import config from "../../../content/meta/config";
import Menu from "../Menu";

import BurgerIcon from "!svg-react-loader!../../images/svg-icons/burger-menu.svg?name=burger";

class Header extends React.Component {
  state = {
    showMenu: false
  };

  componentDidMount() {
    globalHistory.listen(({ action }) => {
      if (this.state.showMenu && action === "PUSH") {
        this.setState({
          showMenu: false
        });
      }
    });
  }

  render() {
    const { pages, path, theme } = this.props;
    const { showMenu } = this.state;

    const HeaderLink = ({ to, children }) => (
      <Link style={{ fontWeight: path === to ? "800" : "400" }} to={to}>
        {children}
      </Link>
    );

    return (
      <React.Fragment>
        <header className={`header fixed`}>
          {/*<div className="header-background-opacity" />*/}
          <div className="container">
            <div className="row">
              <Link to="/" className="logoType">
                <h1>{config.headerTitle}</h1>
              </Link>
              <div className="desktop-menu">
                <span>
                  <HeaderLink to="/rooms">Rooms</HeaderLink>
                </span>
                <span>
                  <HeaderLink to="/gallery">Gallery</HeaderLink>
                </span>
                <span>
                  <HeaderLink to="/news">News</HeaderLink>
                </span>
                <span>
                  <HeaderLink to="/contacts">Contacts</HeaderLink>
                </span>
              </div>
              <div className="mobile-menu">
                <BurgerIcon onClick={() => this.setState({ showMenu: !showMenu })} />
              </div>
            </div>
          </div>
        </header>
        <div className="mobile-menu-container">
          {showMenu && (
            <div
              className="mobile-menu-overlay"
              onClick={() => this.setState({ showMenu: false })}
            />
          )}
          <div className="menu-content">
            <ul>
              <li>
                <HeaderLink to="/rooms">Rooms</HeaderLink>
              </li>
              <li>
                <HeaderLink to="/gallery">Gallery</HeaderLink>
              </li>
              <li>
                <HeaderLink to="/news">News</HeaderLink>
              </li>
              <li>
                <HeaderLink to="/contacts">Contacts</HeaderLink>
              </li>
            </ul>
          </div>
        </div>

        {/* --- STYLES --- */}
        <style jsx>{`
          .header {
            z-index: 1;
            align-items: center;
            justify-content: center;
            display: flex;
            position: relative;
            top: 0;
            width: 100vw;
            align-items: center;
            box-shadow: 0 3px 6px 0px #0000003b;
            background: #ffffff;

            :global(a.logoType) {
              font-family: ${theme.font.family.handwriting};
              color: ${theme.color.brand.primary};
              text-align: left;
              margin: 0 auto;
            }

            &.fixed {
              height: ${theme.header.height.mobile};
              left: 0;
              position: fixed;
              top: 0;
              width: 100vw;
              z-index: 6;
            }
          }

          .header-background-opacity {
            background: #ffffff;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 1;
          }

          h1 {
            font-size: ${theme.font.size.xxxl};
            font-weight: ${theme.font.weight.standard};
            margin: ${theme.space.stack.xs};
            position: relative;
          }

          h2 {
            font-weight: ${theme.font.weight.standard};
            font-size: ${theme.font.size.xxs};
            letter-spacing: 0;
            margin: 0;
          }

          .desktop-menu {
            display: none;
            text-transform: uppercase;
            padding-top: 16px;

            :global(span) {
              padding: 20px;
            }

            :global(a) {
              color: ${theme.color.brand.primary};
              font-family: ${theme.font.family.thaiText};
            }
          }

          .mobile-menu {
            position: absolute;
            right: 1em;
            top: 1.4em;

            :global(svg) {
              fill: ${theme.color.brand.primary};
              width: 2.2em;
              height: 2.2em;
            }
          }

          @from-width tablet {
            .header {
              &.homepage {
                height: ${theme.header.height.mobile};
              }
            }
          }

          @below desktop {
            .mobile-menu-container {
              position: fixed;
              z-index: 2;
              width: 100vw;
              left: 0;
              top: 0;
              height: 100vh;
              opacity: ${showMenu ? "1" : "0"};
              transition: 0.5s opacity ease-out;
              background: #65abd29c;
              pointer-events: ${showMenu ? "initial" : "none"};

              .mobile-menu-overlay {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
              }

              .menu-content {
                position: absolute;
                top: ${showMenu ? "80px" : "-200px"};
                transition: 0.8s top ease-out;
                background: #ffffff;
                width: 100vw;

                :global(ul) {
                  padding: 20px;
                  list-style: none;
                }

                :global(li) {
                  text-align: center;

                  :global(a) {
                    padding: 10px;
                    display: inline-block;
                    width: 100%;
                    font-family: ${theme.font.family.thaiText};
                    color: ${theme.color.brand.primary};
                    text-transform: uppercase;
                  }
                }
              }
            }
          }

          @from-width desktop {
            .header {
              align-items: center;
              display: flex;
              position: absolute;
              top: 0;
              width: 100%;
              justify-content: space-between;
              transition: padding 0.5s;
              height: ${theme.header.height.default};

              &.fixed {
                height: ${theme.header.height.fixed};
                left: 0;
                position: fixed;
                top: 0;
                width: 100%;

                h1 {
                  margin: ${theme.space.stack.xxs};
                }

                h2 {
                  display: none;
                }
              }

              :global(a.logoType) {
                margin: 0 auto 0 0;
                padding: 0 ${theme.space.m};
              }
            }

            .logo {
              margin: ${theme.space.inline.default};

              .fixed & {
                height: 36px;
                width: 36px;
              }
            }

            h2 {
              animation-duration: ${theme.time.duration.default};
              animation-name: h2Entry;
            }

            @keyframes h2Entry {
              from {
                opacity: 0;
              }
              to {
                opacity: 1;
              }
            }

            .desktop-menu {
              display: block;
            }

            .mobile-menu,
            .mobile-menu-container {
              display: none;
              pointer-events: none;
            }
          }
        `}</style>
      </React.Fragment>
    );
  }
}

Header.propTypes = {
  pages: PropTypes.array.isRequired,
  path: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired
};

export default Header;

module.exports = {
  siteTitle: "Peerapat Residence - พีรพัชร์ เรสซิเด้นซ์", // <title>
  shortSiteTitle: "Peerapat Residence - พีรพัชร์ เรสซิเด้นซ์", // <title> ending for posts and pages
  siteDescription: "Peerapat Residence - พีรพัชร์ เรสซิเด้นซ์",
  siteUrl: "https://peerapatresidence.com",
  // pathPrefix: "",
  siteImage: "preview.jpg",
  siteLanguage: "en",

  /* author */
  authorName: "greg lobinski",
  authorTwitterAccount: "greglobinski",

  /* info */
  headerTitle: "Peerapat",
  headerSubTitle: "presents another one GatsbyJS starter",

  /* manifest.json */
  manifestName: "HeroBlog - a GatsbyJS starter",
  manifestShortName: "HeroBlog", // max 12 characters
  manifestStartUrl: "/index.html",
  manifestBackgroundColor: "white",
  manifestThemeColor: "#666",
  manifestDisplay: "standalone",

  // gravatar
  // Use your Gravatar image. If empty then will use src/images/jpg/avatar.jpg
  // Replace your email adress with md5-code.
  // Example https://www.gravatar.com/avatar/g.strainovic@gmail.com ->
  // gravatarImgMd5: "https://www.gravatar.com/avatar/1db853e4df386e8f699e4b35505dd8c6",
  gravatarImgMd5: "",

  // social
  authorSocialLinks: [
    { name: "github", url: "https://github.com/greglobinski" },
    { name: "twitter", url: "https://twitter.com/greglobinski" },
    { name: "facebook", url: "http://facebook.com/greglobinski" }
  ],

  companyName: {
    th: "พีรพัชร์ เรสซิเด้นซ์",
    en: "Peerapat Residence"
  },
  footerSlogan: "Love nature, stay close to nature, stay with us",
  contact: {
    mobile: {
      href: "tel:+66659381621",
      label: "065 938 1621"
    },
    address: {
      th: "343 ซอย พหลโยธิน 53 ถนน พหลโยธิน แขวงอนุสาวรีย์ เขตบางเขน กรุงเทพมหานคร 10220",
      en: "343 ซอย พหลโยธิน 53 Phahonyothin Alley, Anusawari, Bang Khen, Bangkok 10220"
    },
    time: [
      {
        label: {
          th: "จ. - อา."
        },
        value: {
          th: "08:00 น. - 24.00 น."
        }
      }
    ],
    facebook: {
      name: "peerapatresidence",
      url: "https://www.facebook.com/peerapatresidence/"
    },
    line: {
      name: "@peerapatresidence",
      url: "https://page.line.me/peerapatresidence?openQrModal=true"
    }
  },

  // concepts
  concepts: [
    {
      image: "",
      title: "ห้องรีโนเวทใหม่",
      description: "สะอาด ปลอดภัย - มีเฟอร์นิเจอร์ พร้อมเข้าอยู่"
    },
    {
      image: "",
      title: "เดินทางสะดวก",
      description:
        "ทำเลห่างจาก BTS 900 เมตร มีลานจอดรถสำหรับทุกยูนิต : เข้าออก ได้สองทาง ทั้งซอยพหล 53 และ 55"
    },
    {
      image: "",
      title: "ใกล้กับสิ่งอำนวยความสะดวก",
      description:
        "ร้านซะดวกซื้อ 7-11, Family Mart ร้านอาหารตามสั่ง ร้านข้าวมันไก่ ร้านก๋วยเตี๋ยว เครื่องซักผ้าหยอดเหรียญ"
    }
  ],
  labels: {
    size: "ขนาด",
    rental: "ค่าเช่า",
    roomType: "ประเภท",
    bedType: "เตียงนอน",
    recommendation: "แนะนำ",
    electricityUnit: "ค่าไฟ"
  },
  rooms: [
    {
      title: "Peerapat Standard Room",
      detailsURL: "/rooms/standard",
      description: [
        {
          key: "size",
          label: {
            th: "ขนาด"
          },
          value: {
            th: "21 ตร.ม."
          }
        },
        // {
        //   key: "roomType",
        //   label: {
        //     th: "ประเภท"
        //   },
        //   value: {
        //     th: "สตูดิโอ"
        //   }
        // },
        {
          key: "rental",
          label: {
            th: "ค่าเช่า"
          },
          value: {
            th: "4,500 บาท ต่อ เดือน"
          }
        },
        {
          key: "electricity",
          label: {
            th: "ค่าไฟฟ้า"
          },
          value: {
            th: "7 บาท ต่อ ยูนิต ต่อ เดือน (ขั้นต่ำ 100 บาท ต่อ เดือน)"
          }
        },
        {
          key: "water",
          label: {
            th: "ค่าน้ำ"
          },
          value: {
            th: "15 บาท ต่อ ยูนิต ต่อ เดือน (ขั้นต่ำ 100 บาท ต่อ เดือน)"
          }
        },
        {
          key: "vat",
          label: {
            th: "ค่าส่วนกลาง"
          },
          value: {
            th: "3% จากค่าเช่ารวมกับค่าไฟฟ้าและค่าน้ำ ต่อ เดือน"
          }
        },
        {
          key: "vehicle",
          label: {
            th: "ค่าจอดรถ"
          },
          value: {
            th: "รถยนต์ 1000 บาท ต่อ คัน ต่อ เดือน / มอเตอร์ไซต์ 200 บาท ต่อ คัน ต่อ เดือน"
          }
        },
        {
          key: "furniture",
          label: {
            th: "เฟอร์นิเจอร์"
          },
          value: {
            th: "เฟอร์นิเจอร์ ตู้เสื้อผ้า เตียง ผ้าม่าน สุขภัณฑ์"
          }
        },
        {
          key: "ส่วนกลาง",
          label: {
            th: "ส่วนกลาง"
          },
          value: {
            th: "ลิฟต์โดยสาร / ฟรี wi-fi / ไฟส่องสว่าง / กล้องวงจรปิด"
          }
        },
        {
          key: "อื่นๆ",
          label: {
            th: "อื่นๆ"
          },
          value: {
            th:
              "ภายในซอยมีแสงสว่างตลอดทั้งคืน อยู่ใกล้ร้านสะดวกซื้อ 7-11, Family Mart,  ร้านอาหารตามสั่ง ร้านหมูกระทะ"
          }
        }
      ]
    }
  ],
  mapAPIKey: "AIzaSyCMrjXAOhmMPjmACzUGp_8b6y02PsL-2NI",
  mapLatLng: { lat: 13.871232111605956, lng: 100.58961672147603 },
  nearLocation: [
    {
      label: {
        th: "BTS กรมทหารราบที่ 11"
      },
      latLng: { lat: 13.867539256156295, lng: 100.59193528615648 }
    },
    {
      label: {
        th: "BTS วัดพระศรีมหาธาตุ"
      },
      latLng: { lat: 13.8753877515878, lng: 100.59682671276946 }
    },
    {
      label: {
        th: "วัดพระศรีมหาธาตุวรวิหาร"
      },
      latLng: { lat: 13.874265975043192, lng: 100.59339808162864 }
    },
    {
      label: {
        th: "มหาวิทยาลัยศรีปทุม"
      },
      latLng: { lat: 13.855654973482773, lng: 100.58550003033044 }
    },
    {
      label: {
        th: "มหาวิทยาลัยเกษตรศาสตร์"
      },
      latLng: { lat: 13.848534372327443, lng: 100.57240189413014 }
    },
    {
      label: {
        th: "มหาวิทยาลัยราชภัฏพระนคร"
      },
      latLng: { lat: 13.87802657912286, lng: 100.5897971493921 }
    },
    {
      label: {
        th: "เซ็นทรัลพลาซา รามอินทรา"
      },
      latLng: { lat: 13.872340569779393, lng: 100.60196488403805 }
    },
    {
      label: {
        th: "โลตัส หลักสี่"
      },
      latLng: { lat: 13.879378468194606, lng: 100.60051582636561 }
    }
  ]
};

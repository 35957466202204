import "typeface-open-sans";
import FontFaceObserver from "fontfaceobserver";
import PropTypes from "prop-types";
import React from "react";
import { graphql, StaticQuery } from "gatsby";
import { Location } from "@reach/router";

import { getScreenWidth, timeoutThrottlerHandler } from "../utils/helpers";
import Footer from "../components/Footer/";
import Header from "../components/Header";

export const ThemeContext = React.createContext(null);
export const ScreenWidthContext = React.createContext(0);
export const FontLoadedContext = React.createContext(false);

import themeObjectFromYaml from "../theme/theme.yaml";

class Layout extends React.Component {
  constructor() {
    super();

    this.state = {
      font400loaded: false,
      font600loaded: false,
      screenWidth: 0,
      headerMinimized: false,
      theme: themeObjectFromYaml
    };

    if (typeof window !== `undefined`) {
      this.loadFont("font400", "Open Sans", 400);
      this.loadFont("font600", "Open Sans", 600);
    }
  }

  timeouts = {};

  componentDidMount() {
    this.setState({
      screenWidth: getScreenWidth()
    });
    if (typeof window !== "undefined") {
      window.addEventListener("resize", this.resizeThrottler, false);
    }
  }

  resizeThrottler = () => {
    return timeoutThrottlerHandler(this.timeouts, "resize", 100, this.resizeHandler);
  };

  resizeHandler = () => {
    this.setState({ screenWidth: getScreenWidth() });
  };

  isHomePage = () => {
    if (this.props.location.pathname === "/") {
      return true;
    }

    return false;
  };

  loadFont = (name, family, weight) => {
    const font = new FontFaceObserver(family, {
      weight: weight
    });

    font.load(null, 10000).then(
      () => {
        console.log(`${name} is available`);
        this.setState({ [`${name}loaded`]: true });
      },
      () => {
        console.log(`${name} is not available`);
      }
    );
  };

  render() {
    return (
      <StaticQuery
        query={graphql`
          query LayoutQuery {
            pages: allMarkdownRemark(
              filter: { fileAbsolutePath: { regex: "//pages//" }, fields: { prefix: { regex: "/^\\d+$/" } } }
              sort: { fields: [fields___prefix], order: ASC }
            ) {
              edges {
                node {
                  fields {
                    slug
                    prefix
                  }
                  frontmatter {
                    title
                    menuTitle
                  }
                }
              }
            }
            footnote: markdownRemark(fileAbsolutePath: { regex: "/footnote/" }) {
              id
              html
            }
          }
        `}
        render={data => {
          const { children } = this.props;
          const {
            footnote: { html: footnoteHTML },
            pages: { edges: pages }
          } = data;

          return (
            <ThemeContext.Provider value={this.state.theme}>
              <FontLoadedContext.Provider value={this.state.font400loaded}>
                <ScreenWidthContext.Provider value={this.state.screenWidth}>
                  <Location>
                    {locationProps => (
                      <React.Fragment>
                        <Header
                          path={this.props.location.pathname}
                          pages={pages}
                          theme={this.state.theme}
                        />
                        <main>{children}</main>
                        <Footer html={footnoteHTML} theme={this.state.theme} {...locationProps} />

                        {/* --- STYLES --- */}
                        <style jsx>{`
                          main {
                            min-height: 80vh;
                          }
                        `}</style>
                        <style jsx global>{`
                          html {
                            box-sizing: border-box;
                          }
                          *,
                          *:after,
                          *:before {
                            box-sizing: border-box;
                            margin: 0;
                            padding: 0;
                          }
                          body {
                            font-family: ${this.state.font400loaded
                              ? "'Open Sans', sans-serif;"
                              : "Arial, sans-serif;"};
                          }
                          h1,
                          h2,
                          h3 {
                            font-weight: ${this.state.font600loaded ? 600 : 400};
                            line-height: 1.1;
                            letter-spacing: -0.03em;
                            margin: 0;
                          }
                          h1 {
                            letter-spacing: -0.04em;
                          }
                          p {
                            margin: 0;
                          }
                          strong {
                            font-weight: ${this.state.font600loaded ? 600 : 400};
                          }
                          a {
                            text-decoration: none;
                            color: #666;
                          }
                          main {
                            width: auto;
                            display: block;
                          }

                          .container {
                            width: 100%;
                            padding-left: 40px;
                            padding-right: 40px;
                            @below desktop {
                              padding-left: 20px;
                              padding-right: 20px;
                            }
                          }
                          .container {
                            margin-left: auto;
                            margin-right: auto;
                            max-width: 1366px;
                            @below desktop {
                              max-width: 1024px;
                            }
                            @below tablet {
                              max-width: unset;
                            }
                          }
                          .container.fluid {
                            max-width: unset !important;
                          }

                          .row {
                            margin-left: -20px;
                            margin-right: -20px;
                            display: flex;
                          }
                        `}</style>
                      </React.Fragment>
                    )}
                  </Location>
                </ScreenWidthContext.Provider>
              </FontLoadedContext.Provider>
            </ThemeContext.Provider>
          );
        }}
      />
    );
  }
}

Layout.propTypes = {
  children: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default Layout;

//eslint-disable-next-line no-undef
/*
export const postQuery = graphql`
  query LayoutQuery {
    pages: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "//pages//" }, fields: { prefix: { regex: "/^\\d+$/" } } }
      sort: { fields: [fields___prefix], order: ASC }
    ) {
      edges {
        node {
          fields {
            slug
            prefix
          }
          frontmatter {
            title
            menuTitle
          }
        }
      }
    }
    footnote: markdownRemark(fileAbsolutePath: { regex: "/footnote/" }) {
      id
      html
    }
  }
`;

*/

import React from "react";
import PropTypes from "prop-types";
import config from "../../../content/meta/config";

const Footer = props => {
  const { theme } = props;

  return (
    <React.Fragment>
      <h1 className="footer-slogan">{config.footerSlogan}</h1>

      {/* --- STYLES --- */}
      <style jsx>{`
        .footer-slogan {
          font-family: ${theme.font.family.simpleHeader};
          text-align: center;
          text-transform: uppercase;
          color: ${theme.color.brand.primary};
          font-size: 1.6em;
          font-weight: normal;
          letter-spacing: 0.05px;
          padding: 1em;
        }

        @from-width desktop {
          .footer-slogan {
            font-size: ${theme.font.size.xxl};
          }
        }
      `}</style>
    </React.Fragment>
  );
};

Footer.propTypes = {
  theme: PropTypes.object.isRequired
};

export default Footer;

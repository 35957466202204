import React from "react";
import PropTypes from "prop-types";
import config from "../../../content/meta/config";

import FacebookIcon from "!svg-react-loader!../../images/svg-icons/facebook.svg?name=facebook";
import LineIcon from "!svg-react-loader!../../images/svg-icons/line.svg?name=line";

const SocialLinks = () => (
  <>
    <a href={config.contact.facebook.url} target="_blank" rel="noopener noreferrer" alt="facebook">
      <FacebookIcon alt="facebook" />
    </a>
    <a href={config.contact.line.url} target="_blank" rel="noopener noreferrer" alt="line">
      <LineIcon alt="line" />
    </a>
  </>
);

const Footer = props => {
  const { theme, location } = props;

  const isContacts = location.pathname === "/contacts";

  const renderTimes = () =>
    config.contact.time.map(item => (
      <React.Fragment key={JSON.stringify(item)}>
        <div className="time-label">{item.label.th}</div>
        <div className="time-value">{item.value.th}</div>
      </React.Fragment>
    ));

  return (
    <React.Fragment>
      <footer className="footer">
        <div className="container">
          {!isContacts && (
            <div className="content">
              <div className="contact">
                <div className="mobile-number">
                  <a href={config.contact.mobile.href}>{config.contact.mobile.label}</a>
                </div>
                <div className="social mobile">
                  <SocialLinks />
                </div>
                <div className="time mobile">{renderTimes()}</div>
                <div className="company">
                  <div>{config.companyName.th}</div>
                  <div>{config.contact.address.th}</div>
                </div>
              </div>
              <div className="time desktop">{renderTimes()}</div>
              <div className="social desktop">
                <SocialLinks />
              </div>
            </div>
          )}
          {isContacts && <div className="thankyou">Thank you for visiting</div>}
          <div className="copyright">© 2021 {config.companyName.en} - Powered by kanoonth.com</div>
        </div>
      </footer>

      {/* --- STYLES --- */}
      <style jsx>{`
        .footer {
          background: ${theme.color.brand.darkFooter};
          font-family: ${theme.font.family.thaiText};
          font-size: ${theme.font.size.xs};
          color: ${theme.color.neutral.white};
          padding-top: 0;
          padding-bottom: 20px;
          overflow: hidden;
        }

        .content {
          display: block;
        }

        .contact {
          width: 100%;
        }

        .mobile-number {
          font-size: ${theme.font.size.m};
          padding: 1.5em 0 0.5em;
          text-align: center;
          font-weight: 500;

          a {
            color: white;
            text-decoration: none;

            &:hover {
              opacirt: 0.5;
            }
          }
        }

        .company {
          text-align: center;
          width: 70%;
          margin: 0 auto;
          line-height: 1.6em;
        }

        .time {
          padding: 0 0 1em;
          line-height: 1.6em;
          text-align: center;

          &.desktop {
            display: none;
          }
        }

        .time-label {
          font-weight: bold;
        }

        .social {
          padding: 1em;
          margin: 0 auto;
          text-align: center;

          & > :global(a) {
            margin: 0 10px;
          }

          :global(svg) {
            fill: #ffffff;
            width: 2.6em;
            height: 2.6em;
          }

          &.desktop {
            display: none;
          }
        }

        .copyright {
          margin: 1.5em 0 0 0;
          font-size: ${theme.font.size.xxs};
          text-align: center;
        }

        .thankyou {
          font-family: ${theme.font.family.handwriting};
          text-align: center;
          font-size: x-large;
          margin: 3em 0;
        }

        @from-width desktop {
          .footer {
            padding-top: 1.5em;
            padding-bottom: 1.5em;
          }

          .content {
            display: flex;
            height: 300px;
          }

          .contact {
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;
          }

          .mobile-number {
            padding: 0 0 2em 0;
          }

          .social {
            padding: 7em;
          }

          .social.desktop {
            padding: 0;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .social.mobile,
          .time.mobile {
            display: none;
          }

          .time.desktop {
            padding: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
          }

          .time {
            padding: 5em 0 0;
            text-align: left;
          }
        }
      `}</style>
    </React.Fragment>
  );
};

Footer.propTypes = {
  theme: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default Footer;
